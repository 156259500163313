
<template>
    <div data-app>
        <v-app>
            <div class="row">
                <div class="col col-12">
                    <div class="d-flex coin-label mx-4 my-4">
                        <img :src="require('@/assets/images/coin.svg')">
                        <p><span class="fastcoins">FastCoins</span> acumulados: <span class="coins">{{coin}}</span></p>
                    </div>
                </div>
                <div class="col col-md-4 col-12">
                    <div class="avatar-preview" :key="currentKey">
                        <img v-for="item in currentAvatar" class="avatar-image" :src="item.file" :key="item._id" :style="`z-index: ${item.zindex-900}`">
                    </div>
                    <button class="btn btn-primary update-button" v-on:click="update()">UPDATE</button>
                </div>
                <div class="col col-md-8 col-12 avatar-assets">
                    <div class="card" v-for="(avatar, type) in avatars" :key="type">
                        <div class="card-header"  v-if="type != 'body'">
                        <h4 class="text-uppercase">
                            {{type}}
                        </h4>
                        </div>

                        <div class="card-body avatar-box overflow-auto"  v-if="type != 'body'">
                            <div class="text-center mx-2 avatar-thumb" 
                            v-for="item in avatar" 
                            :key="item._id"
                            >
                                <img :src="item.thumb" alt="thumb" class="img-thumbnail" 
                                :class="{
                                    selected: item.selected,
                                    purchased: item.purchased,
                                    nonavailable: !item.available
                                }">
                                <div class="price text-center">
                                    <img :src="require('@/assets/images/coin.svg')"><span class="coins"> {{item.price}}</span> 
                                    <span class="buy-btn" v-if="!item.purchased" v-on:click="buyItem(item)">BUY</span>
                                    <span class="current-btn" v-if="item.selected">CURRENT</span>
                                    <span class="select-btn" v-if="item.purchased && !item.selected" v-on:click="selectItem(item)">SELECT</span>
                                </div>
                                <i class="close flaticon-cancel" v-if="item.selected && item.deselectable" v-on:click="deselectItem(item)"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <v-dialog
                v-model="dialog"
                max-width="400"
                class="pay-dialog"
            >
                <v-card :style="{ backgroundImage: 'url(' + require('@/assets/images/coin.gif') + ')' }">

                <v-card-text class="">
                    
                </v-card-text>

                <v-card-actions class="pay-action">
                    <v-spacer></v-spacer>
                    <div class="paying-coin">
                        <img :src="require('@/assets/images/coin.svg')"> 
                        <span class="coins">{{purchasingAvatar.price}}</span>
                    </div>
                    <button class="btn btn-primary pay-button" v-on:click="purchaseItem()">Pay</button>
                </v-card-actions>
                </v-card>
            </v-dialog>
        </v-app>
    </div>

    
    
</template>

<style lang="scss">
@import "@/assets/sass/pages/user/avatar.scss";
</style>


<script>

import { mapGetters } from "vuex";
import {
  UPDATE_AVATAR,
  PURCHASE_AVATAR
} from "@/core/services/store/profile.module.js";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "avatar",
  components: {
  },
  mounted() {
    this.currentAvatar = this.currentUser.currentAvatar;
    this.purchasedItems = this.currentUser.purchasedItems;
    this.currentAvatarIds = this.currentUser.avatar;
    this.coin = this.currentUser.points;

    const deselectableTypes = [
        'under_hair',
        'glasses',
        'backitem',
        'jackets',
        'polo',
        'watchs'
    ];

    for(let [type, avatars] of Object.entries(this.currentUser.avatars)) {
        this.avatars[type] = [];
        for(let item of avatars) {
            if(this.currentAvatarIds.includes(item._id)) {
                item.selected = true;
            } else {
                item.selected = false;
            }

            if(this.purchasedItems.includes(item._id)) {
                item.purchased = true;
            } else {
                item.purchased = false;
            }

            if(item.price > this.coin) {
                item.available = false;
            } else {
                item.available = true;
                if(item.price == 0) {
                    item.purchased = true;
                }
            }

            if(deselectableTypes.includes(item.type)) {
                item.deselectable = true;
            } else {
                item.deselectable = false;
            }

            this.avatars[type].push(item);
        }
    }

  },
  data() {
      return {
        currentAvatar: [],
        currentAvatarIds: [],
        avatars: {},
        purchasedItems: [],
        purchasingAvatar: [],
        coin: 0,
        currentKey: 0,
        dialog: false,
      }
  },
  methods: {
    selectItem(avatar) {
        for(let i in this.avatars[avatar.type]) {
            if(avatar._id == this.avatars[avatar.type][i]._id) {
                this.avatars[avatar.type][i].selected = true;
            } else {
                this.avatars[avatar.type][i].selected = false;
            }
        }

        this.currentAvatar = [];
        this.currentAvatarIds = [];

        for(let avatars of Object.values(this.avatars)) {
            for(let item of avatars) {
                if(item.selected) {
                    this.currentAvatar.push(item);
                    this.currentAvatarIds.push(item._id);
                }
            }
        }

        this.currentKey++;
    },

    deselectItem(avatar) {
        for(let i in this.avatars[avatar.type]) {
            if(avatar._id == this.avatars[avatar.type][i]._id) {
                this.avatars[avatar.type][i].selected = false;
            }
        }

        this.currentAvatar = [];
        this.currentAvatarIds = [];

        for(let avatars of Object.values(this.avatars)) {
            for(let item of avatars) {
                if(item.selected) {
                    this.currentAvatar.push(item);
                    this.currentAvatarIds.push(item._id);
                }
            }
        }

        this.currentKey++;
    },

    buyItem(avatar) {
        if(this.coin >= avatar.price) {
            this.dialog = true;
            this.purchasingAvatar = avatar;
        }
    },

    purchaseItem() {
        this.dialog = false;
        this.purchasedItems.push(this.purchasingAvatar._id);

        this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
        this.coin -= this.purchasingAvatar.price;

        this.$store.dispatch(PURCHASE_AVATAR, {price: this.purchasingAvatar.price, items: this.purchasedItems})
          .then(() => {
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            for(let i in this.avatars[this.purchasingAvatar.type]) {
                if(this.purchasingAvatar._id == this.avatars[this.purchasingAvatar.type][i]._id) {
                    this.avatars[this.purchasingAvatar.type][i].purchased = true;
                    break;
                }
            }
            this.currentKey++;
          })
          .catch(() => {
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          });
    },

    update() {
        this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

        this.$store.dispatch(UPDATE_AVATAR, this.currentAvatarIds)
          .then(() => {
            this.$router.push({name: 'begin'})
          })
          .catch(() => {
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          });
    }

  },
  computed: {
        ...mapGetters([
            "currentUser",
        ]),
   }
}
</script>